import React from "react";
import Login from "./Login";

const UserAuth = () => {
  return (
    <>
      <div className="hpfwhm">
        <Login />
      </div>
    </>
  );
};

export default UserAuth;
