import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Main from './Compnents/Main';
import Header from './Compnents/Header'
import UserAuth from './Compnents/UserAuth';
import Profile from './Compnents/Profile';
import AutoPaper from './Compnents/AutoPaper';


function App() {
  return (
    <div className='mainScreen'>
    <Header />
    <Router>
      <Routes>
        <Route exact path='/' element={<UserAuth />}></Route>
        <Route path='/home' element={<Main />}></Route>
        <Route path='/profile' element={<Profile />}></Route>
        <Route path='/autoPaper' element={<AutoPaper />}></Route>
      </Routes>
    </Router>
    </div>
  );
}

export default App;
