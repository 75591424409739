import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

const Profile = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const docRef = doc(db, "user", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const userData = docSnap.data();
          setUser(userData);
        }
      }
    });

    return unsubscribe;

  }, []);

  return (
    <>
      <div className="mphm">
        <Sidebar />
        <div className="profile">
          <h1>My Account Profile</h1>
          <div className="ppidchm">
            <img alt="Avatar" src={user && user.gender === "male" ? "male-avatar.png" : "female-avatar.png"} />
            <div>
              <h2>Name: {user && user.name}</h2>
              <p>Email: {user && user.email}</p>
              <p>Phone Number: {user && user.phone}</p>
              <p>School Name: {user && user.school}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
