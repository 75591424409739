import React, { useState } from "react";
import { useNavigate } from 'react-router'
import Logininput from "./Logininput";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "./firebase";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  function handleLogin() {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in
        const user = userCredential.user;
        console.log(user);
        // Redirect to the next screen
        navigate("/home");
      })
      .catch((error) => {
        const errorCode = error.code;
        console.log(errorCode);
        alert(errorCode)
        const errorMessage = error.message;
        console.log(errorMessage);
      });
  }
  
  return (
    <div className="libhm">
      <h1>Login</h1>
      <Logininput
        placeholder="User Name"
        type="text"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <Logininput
        placeholder="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      <button class="login-btn" onClick={handleLogin}>
        Login
      </button>
    </div>
  );
};

export default Login;
