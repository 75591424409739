import React from "react";

const Header = () => {
  return (
    <header>
      <div className="wltophm"></div>
      <div className="mltophm"><img alt="Avatar" src="logo.png" />
      <div>Abbasi auto paper genrator</div>
      </div>
    </header>
  );
};

export default Header;
