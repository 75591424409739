import React, { useState, useEffect } from "react";
import Sidebar from "./Sidebar";
import { doc, getDoc } from "firebase/firestore";
import { db, auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";
import _ from "lodash";

const AutoPaper = () => {
  const [classes, setClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [schoolName, setSchoolName] = useState("");
  const [className, setClassName] = useState("");
  const [chapterList, setChapterList] = useState([]);
  const [subjectName, setSubjectName] = useState("");
  const [showChapterModel, setShowChapterModel] = useState(false);
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [showQuestionsModel, setShowQuestionsModel] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [showPaper, setShowPaper] = useState(false);
  const [date, setDate] = useState(new Date().toLocaleDateString("en-GB"));
  const [imageUrl, setImageUrl] = useState(null);

  const [MCQsCheck, setMCQsChecked] = useState(false);
  const [TFCheck, setTFChecked] = useState(false);
  const [FBCheck, setFBChecked] = useState(false);
  const [SQCheck, setSQChecked] = useState(false);
  const [CMCheck, setCMChecked] = useState(false);
  const [LQCheck, setLQChecked] = useState(false);
  const [GCheck, setGChecked] = useState(false);

  const [availableMCQCount, setAvailableMCQCount] = useState();
  const [availableTFCount, setAvailableTFCount] = useState();
  const [availableFBCount, setAvailableFBCount] = useState();
  const [availableSQCount, setAvailableSQCount] = useState();
  const [availableCMCount, setAvailableCMCount] = useState();
  const [availableLQCount, setAvailableLQCount] = useState();
  const [availableGCount, setAvailableGCount] = useState();

  const [totalMCQMarks, SetTotalMCQMarks] = useState(0);
  const [totalTFMarks, SetTotalTFMarks] = useState(0);
  const [totalFBMarks, SetTotalFBMarks] = useState(0);
  const [totalSQMarks, SetTotalSQMarks] = useState(0);
  const [totalCMMarks, SetTotalCMMarks] = useState(0);
  const [totalLQMarks, SetTotalLQMarks] = useState(0);
  const [totalGMarks, SetTotalGMarks] = useState(0);

  const [MCQSelected, SetMCQSelected] = useState(0);
  const [TFSelected, SetTFSelected] = useState(0);
  const [FBSelected, SetFBSelected] = useState(0);
  const [SQSelected, SetSQSelected] = useState(0);
  const [CMSelected, SetCMSelected] = useState(0);
  const [LQSelected, SetLQSelected] = useState(0);
  const [GSelected, SetGSelected] = useState(0);

  const [MCQMarks, SetMCQMarks] = useState(0);
  const [TFMarks, SetTFMarks] = useState(0);
  const [FBMarks, SetFBMarks] = useState(0);
  const [SQMarks, SetSQMarks] = useState(0);
  const [CMMarks, SetCMMarks] = useState(0);
  const [LQMarks, SetLQMarks] = useState(0);
  const [GMarks, SetGMarks] = useState(0);

  const [MCQs, setMCQs] = useState([]);
  const [TF, setTF] = useState([]);
  const [FB, setFB] = useState([]);
  const [SQ, setSQ] = useState([]);
  const [CM, setCM] = useState([]);
  const [LQ, setLQ] = useState([]);
  const [G, setG] = useState([]);

  useEffect(() => {
    const fetchClasses = async () => {
      const docRef = doc(db, "classes", "classes");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const { classes } = docSnap.data();
        setClasses(classes);
      }
    };
    fetchClasses();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const docRef = doc(db, "user", uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const school = docSnap.data().school;
          setSchoolName(school);
        }
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (selectedChapters.length === 0) {
      setShowQuestionsModel(false);
      clearBoxes();
    }
  }, [selectedChapters]);

  const clearBoxes = () => {
    setAvailableMCQCount();
    setAvailableTFCount();
    setAvailableFBCount();
    setAvailableSQCount();
    setAvailableCMCount();
    setAvailableLQCount();
    setAvailableGCount();
    SetTotalMCQMarks(0);
    SetTotalTFMarks(0);
    SetTotalFBMarks(0);
    SetTotalSQMarks(0);
    SetTotalCMMarks(0);
    SetTotalLQMarks(0);
    SetTotalGMarks(0);
    SetMCQSelected(0);
    SetTFSelected(0);
    SetFBSelected(0);
    SetSQSelected(0);
    SetCMSelected(0);
    SetLQSelected(0);
    SetGSelected(0);
    SetMCQMarks(0);
    SetTFMarks(0);
    SetFBMarks(0);
    SetSQMarks(0);
    SetCMMarks(0);
    SetLQMarks(0);
    SetGMarks(0);
    setMCQs([]);
    setTF([]);
    setFB([]);
    setCM([]);
    setLQ([]);
    setG([]);
    setMCQsChecked(false);
    setTFChecked(false);
    setFBChecked(false);
    setSQChecked(false);
    setCMChecked(false);
    setLQChecked(false);
    setGChecked(false);
  };

  async function fatchSubjects(e) {
    const docRef = doc(db, "classes", e.target.value);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const subject = docSnap.data().subject;
      setSubjects(subject);
      setClassName(e.target.value);
    }
  }

  const loadChapters = async () => {
    const ref = `${className} ${subjectName}`;
    const docRef = doc(db, "classes", ref);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const chapters = docSnap.data().chapter;
      setChapterList(chapters);
      setShowChapterModel(true);
    }
  };

  // This is creating the list of chapters on ceckbox
  const handleCheckboxChange = (e) => {
    const chapterName = e.target.value;
    if (e.target.checked) {
      const array = [...selectedChapters, chapterName];
      setSelectedChapters(array);
    } else {
      setSelectedChapters(
        selectedChapters.filter((chapter) => chapter !== chapterName)
      );
    }
  };

  const hndleMCQChecked = async (e) => {
    if (e.target.checked) {
      let count = 0;
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const QCount = docSnap.data().MCQs;
          count += QCount.length;
        }
      }
      setAvailableMCQCount(count);
      setMCQsChecked(true);
    } else if (!e.target.checked) {
      setAvailableMCQCount("");
      setMCQsChecked(false);
      SetTotalMCQMarks(0);
      SetMCQSelected(0);
      SetMCQMarks(0);
      setTotalMarks(0);
    }
  };

  const handleTFChecked = async (e) => {
    if (e.target.checked) {
      let count = 0;
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const QCount = docSnap.data().TrueFalse;
          count += QCount.length;
        }
      }
      setAvailableTFCount(count);
      setTFChecked(true);
    } else if (!e.target.checked) {
      setAvailableTFCount("");
      setTFChecked(false);
      SetTotalTFMarks(0);
      SetTFSelected(0);
      SetTFMarks(0);
      setTotalMarks(0);
    }
  };

  const handleFBChecked = async (e) => {
    if (e.target.checked) {
      let count = 0;
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const QCount = docSnap.data().fillBlank;
          count += QCount.length;
        }
      }
      setAvailableFBCount(count);
      setFBChecked(true);
    } else if (!e.target.checked) {
      setAvailableFBCount("");
      setFBChecked(false);
      SetTotalFBMarks(0);
      SetFBMarks(0);
      SetFBSelected(0);
      setTotalMarks(0);
    }
  };

  const handleSQChecked = async (e) => {
    if (e.target.checked) {
      let count = 0;
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const QCount = docSnap.data().ShortQ;
          count += QCount.length;
        }
      }
      setAvailableSQCount(count);
      setSQChecked(true);
    } else if (!e.target.checked) {
      setAvailableSQCount("");
      setSQChecked(false);
      SetTotalSQMarks(0);
      SetSQMarks(0);
      SetSQSelected(0);
      setTotalMarks(0);
    }
  };

  const handleCMChecked = async (e) => {
    if (e.target.checked) {
      let count = 0;
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const QCount = docSnap.data().ColumnMatch;
          count += QCount.length;
        }
      }
      setAvailableCMCount(count);
      setCMChecked(true);
    } else if (!e.target.checked) {
      setAvailableCMCount("");
      setCMChecked(false);
      SetTotalCMMarks(0);
      SetCMMarks(0);
      SetCMSelected(0);
      setTotalMarks(0);
    }
  };

  const handleLQChecked = async (e) => {
    if (e.target.checked) {
      let count = 0;
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const QCount = docSnap.data().LongQ;
          count += QCount.length;
        }
      }
      setAvailableLQCount(count);
      setLQChecked(true);
    } else if (!e.target.checked) {
      setAvailableLQCount("");
      setLQChecked(false);
      SetTotalLQMarks(0);
      SetLQMarks(0);
      SetLQSelected(0);
      setTotalMarks(0);
    }
  };

  const handleGChecked = async (e) => {
    if (e.target.checked) {
      let count = 0;
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const QCount = docSnap.data().Grammar;
          count += QCount.length;
        }
      }
      setAvailableGCount(count);
      setGChecked(true);
    } else if (!e.target.checked) {
      setAvailableGCount("");
      setGChecked(false);
      SetTotalGMarks(0);
      SetGSelected(0);
      SetGMarks(0);
      setTotalMarks(0);
    }
  };

  function handleMCQSelectedChange(event) {
    const value = parseInt(event.target.value);
    SetMCQSelected(value);
    SetTotalMCQMarks(calculateTotalMarks(value, MCQMarks));
  }

  function handleMCQMarksChange(event) {
    const value = parseInt(event.target.value);
    SetMCQMarks(value);
    SetTotalMCQMarks(calculateTotalMarks(MCQSelected, value));
  }

  function handleTFSelectedChange(event) {
    const value = parseInt(event.target.value);
    SetTFSelected(value);
    SetTotalTFMarks(calculateTotalMarks(value, TFMarks));
  }

  function handleTFMarksChange(event) {
    const value = parseInt(event.target.value);
    SetTFMarks(value);
    SetTotalTFMarks(calculateTotalMarks(TFSelected, value));
  }

  function handleFBSelectedChange(event) {
    const value = parseInt(event.target.value);
    SetFBSelected(value);
    SetTotalFBMarks(calculateTotalMarks(value, FBMarks));
  }

  function handleFBMarksChange(event) {
    const value = parseInt(event.target.value);
    SetFBMarks(value);
    SetTotalFBMarks(calculateTotalMarks(FBSelected, value));
  }

  function handleSQSelectedChange(event) {
    const value = parseInt(event.target.value);
    SetSQSelected(value);
    SetTotalSQMarks(calculateTotalMarks(value, SQMarks));
  }

  function handleSQMarksChange(event) {
    const value = parseInt(event.target.value);
    SetSQMarks(value);
    SetTotalSQMarks(calculateTotalMarks(SQSelected, value));
  }

  function handleCMSelectedChange(event) {
    const value = parseInt(event.target.value);
    SetCMSelected(value);
    SetTotalCMMarks(calculateTotalMarks(value, CMMarks));
  }

  function handleCMMarksChange(event) {
    const value = parseInt(event.target.value);
    SetCMMarks(value);
    SetTotalCMMarks(calculateTotalMarks(CMSelected, value));
  }

  function handleLQSelectedChange(event) {
    const value = parseInt(event.target.value);
    SetLQSelected(value);
    SetTotalLQMarks(calculateTotalMarks(value, LQMarks));
  }

  function handleLQMarksChange(event) {
    const value = parseInt(event.target.value);
    SetLQMarks(value);
    SetTotalLQMarks(calculateTotalMarks(LQSelected, value));
  }

  function handleGSelectedChange(event) {
    const value = parseInt(event.target.value);
    SetGSelected(value);
    SetTotalGMarks(calculateTotalMarks(value, GMarks));
  }

  function handleGMarksChange(event) {
    const value = parseInt(event.target.value);
    SetGMarks(value);
    SetTotalGMarks(calculateTotalMarks(GSelected, value));
  }

  function calculateTotalMarks(selected, marks) {
    return selected * marks;
  }

  const handleCalculateTotalMarks = () => {
    const calculatedTotal =
      (totalMCQMarks || 0) +
      (totalTFMarks || 0) +
      (totalFBMarks || 0) +
      (totalCMMarks || 0) +
      (totalGMarks || 0) +
      (totalLQMarks || 0) +
      (totalSQMarks || 0);

    if (Number.isNaN(calculatedTotal)) {
      setTotalMarks("NaN");
    } else {
      setTotalMarks(calculatedTotal);
    }
  };

  const printPaper = () => {
    let printContents = document.getElementById("printablediv").innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;
  };

  const printData = async () => {
    if (MCQsCheck) {
      // setMCQs([]);
      const fetchedMCQs = [];
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const mcqs = docSnap.data().MCQs;
          fetchedMCQs.push(...mcqs);
        }
      }
      // Shuffle the fetched questions
      const shuffledMCQs = _.shuffle(fetchedMCQs);

      // Select a specific number of questions
      const selectedMCQs = shuffledMCQs.slice(0, MCQSelected);

      setMCQs(selectedMCQs);
    }
    if (TFCheck) {
      setTF([]);
      const fetchedTF = [];
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const tf = docSnap.data().TrueFalse;
          fetchedTF.push(...tf);
        }
      }
      // Shuffle the fetched questions
      const shuffledTF = _.shuffle(fetchedTF);

      // Select a specific number of questions
      const selectedTF = shuffledTF.slice(0, TFSelected);

      setTF(selectedTF);
    }
    if (FBCheck) {
      setFB([]);
      const fetchedFB = [];
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const fb = docSnap.data().fillBlank;
          fetchedFB.push(...fb);
        }
      }
      // Shuffle the fetched questions
      const shuffledFB = _.shuffle(fetchedFB);

      // Select a specific number of questions
      const selectedFB = shuffledFB.slice(0, FBSelected);

      setFB(selectedFB);
    }
    if (SQCheck) {
      setSQ([]);
      const fetchedSQ = [];
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const sq = docSnap.data().ShortQ;
          fetchedSQ.push(...sq);
        }
      }
      // Shuffle the fetched questions
      const shuffledSQ = _.shuffle(fetchedSQ);

      // Select a specific number of questions
      const selectedSQ = shuffledSQ.slice(0, SQSelected);

      setSQ(selectedSQ);
    }
    if (CMCheck) {
      setCM([]);
      const fetchedCM = [];
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const cm = docSnap.data().ColumnMatch;
          fetchedCM.push(...cm);
        }
      }
      // Shuffle the fetched questions
      const shuffledCM = _.shuffle(fetchedCM);

      // Select a specific number of questions
      const selectedCM = shuffledCM.slice(0, CMSelected);

      setCM(selectedCM);
    }
    if (LQCheck) {
      setLQ([]);
      const fetchedLQ = [];
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const lq = docSnap.data().LongQ;
          fetchedLQ.push(...lq);
        }
      }
      // Shuffle the fetched questions
      const shuffledLQ = _.shuffle(fetchedLQ);

      // Select a specific number of questions
      const selectedLQ = shuffledLQ.slice(0, LQSelected);

      setLQ(selectedLQ);
    }
    if (GCheck) {
      setG([]);
      const fetchedG = [];
      for (let index = 0; index < selectedChapters.length; index++) {
        const docRef = doc(
          db,
          "classes",
          `${className} ${subjectName} ${selectedChapters[index]}`
        );
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const g = docSnap.data().Grammar;
          fetchedG.push(...g);
        }
      }
      // Shuffle the fetched questions
      const shuffledG = _.shuffle(fetchedG);

      // Select a specific number of questions
      const selectedG = shuffledG.slice(0, GSelected);
      setG(selectedG);
    }
    setShowPaper(true);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const imageDataUrl = event.target.result;
      setImageUrl(imageDataUrl);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="mphm">
      <Sidebar />
      <div className="maindiv">
        <h1>Auto Paper</h1>
        <div className="dropdowns">
          <input
            type="date"
            value={date}
            defaultValue={new Date().toISOString().slice(0, 10)}
            onChange={(e) =>
              setDate(e.target.value.split("-").reverse().join("-"))
            }
          />
          <select onChange={fatchSubjects}>
            <option value="">--Select Class--</option>
            {classes &&
              classes.map((element) => (
                <option label={element} value={element}>
                  {element}
                </option>
              ))}
          </select>
          <select
            onChange={(e) => {
              setSubjectName(e.target.value);
            }}
          >
            <option value="">--Select Subject--</option>
            {subjects &&
              subjects.map((element) => (
                <option label={element} value={element}>
                  {element}
                </option>
              ))}
          </select>
          <button onClick={loadChapters} disabled={!className || !subjectName}>
            Load Chapters
          </button>
        </div>
        {showChapterModel && (
          <>
            <h5>Select Chapters</h5>
            <div className="dropdowns">
              {chapterList &&
                chapterList.map((element) => (
                  <div className="child">
                    <input
                      type="checkbox"
                      id={element}
                      value={element}
                      name={element}
                      onChange={handleCheckboxChange}
                    />
                    <label htmlFor={element}>{element}</label>
                  </div>
                ))}
              {/* <hr /> */}
              <button
                onClick={() => setShowQuestionsModel(true)}
                disabled={selectedChapters.length === 0}
              >
                Load Slo & Questions
              </button>
            </div>
          </>
        )}
        {showQuestionsModel && (
          <>
            <h5>Select Question Type</h5>
            <div className="questionModel">
              <table>
                <tr>
                  <th>Select</th>
                  <th>Question Type</th>
                  <th>Number of Questions You want?</th>
                  <th>Question (Marks)</th>
                  <th>Total Marks</th>
                  <th>Attempt Questions</th>
                  {/* <th>Total Selected Question Marks</th> */}
                  <th>Available Questions</th>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      id="MCQs"
                      value="MCQs"
                      name="MCQs"
                      onChange={hndleMCQChecked}
                    />
                  </td>
                  <td>
                    <label htmlFor="MCQs">MCQs</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={MCQSelected}
                      onChange={handleMCQSelectedChange}
                      readOnly={!MCQsCheck}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={MCQMarks}
                      onChange={handleMCQMarksChange}
                      readOnly={!MCQsCheck}
                    />
                  </td>
                  <td>
                    <input type="number" value={totalMCQMarks} readOnly />
                  </td>
                  <td>
                    <input type="number" />
                  </td>
                  {/* <td>
      <input type="number"
      inputMode="num />
    </td> */}
                  <td>
                    <input
                      type="number"
                      value={availableMCQCount}
                      id={availableMCQCount}
                      name={availableMCQCount}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      id="TrueFalse"
                      value="TrueFalse"
                      name="TrueFalse"
                      onChange={handleTFChecked}
                    />
                  </td>
                  <td>
                    <label htmlFor="TrueFalse">True/False</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={TFSelected}
                      onChange={handleTFSelectedChange}
                      readOnly={!TFCheck}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={TFMarks}
                      onChange={handleTFMarksChange}
                      readOnly={!TFCheck}
                    />
                  </td>
                  <td>
                    <input type="number" value={totalTFMarks} readOnly />
                  </td>
                  <td>
                    <input type="number" />
                  </td>
                  {/* <td>
      <input type="number"
      inputMode="num />
    </td> */}
                  <td>
                    <input
                      type="number"
                      value={availableTFCount}
                      id={availableTFCount}
                      name={availableTFCount}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      id="FillBlank"
                      value="FillBlank"
                      name="FillBlank"
                      onChange={handleFBChecked}
                    />
                  </td>
                  <td>
                    <label htmlFor="FillBlank">Fill in the Blanks</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={FBSelected}
                      onChange={handleFBSelectedChange}
                      readOnly={!FBCheck}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={FBMarks}
                      onChange={handleFBMarksChange}
                      readOnly={!FBCheck}
                    />
                  </td>
                  <td>
                    <input type="number" value={totalFBMarks} readOnly />
                  </td>
                  <td>
                    <input type="number" />
                  </td>
                  {/* <td>
      <input type="number"
      inputMode="num />
    </td> */}
                  <td>
                    <input
                      type="number"
                      value={availableFBCount}
                      id={availableFBCount}
                      name={availableFBCount}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      id="ShortQ"
                      value="ShortQ"
                      name="ShortQ"
                      onChange={handleSQChecked}
                    />
                  </td>
                  <td>
                    <label htmlFor="ShortQ">Short Question</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={SQSelected}
                      onChange={handleSQSelectedChange}
                      readOnly={!SQCheck}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={SQMarks}
                      onChange={handleSQMarksChange}
                      readOnly={!SQCheck}
                    />
                  </td>
                  <td>
                    <input type="number" value={totalSQMarks} readOnly />
                  </td>
                  <td>
                    <input type="number" />
                  </td>
                  {/* <td>
      <input type="number"
      inputMode="num />
    </td> */}
                  <td>
                    <input
                      type="number"
                      value={availableSQCount}
                      id={availableSQCount}
                      name={availableSQCount}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      id="CoulmensMatch"
                      value="CoulmensMatch"
                      name="CoulmensMatch"
                      onChange={handleCMChecked}
                    />
                  </td>
                  <td>
                    <label htmlFor="CoulmensMatch">Coulmens Match</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={CMSelected}
                      onChange={handleCMSelectedChange}
                      readOnly={!CMCheck}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={CMMarks}
                      onChange={handleCMMarksChange}
                      readOnly={!CMCheck}
                    />
                  </td>
                  <td>
                    <input type="number" value={totalCMMarks} readOnly />
                  </td>
                  <td>
                    {/* <input type="number"
                  
             /> */}
                  </td>
                  {/* <td>
      <input type="number"
      inputMode="num />
    </td> */}
                  <td>
                    <input
                      type="number"
                      value={availableCMCount}
                      id={availableCMCount}
                      name={availableCMCount}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      id="LongQuestion"
                      value="LongQuestion"
                      name="LongQuestion"
                      onChange={handleLQChecked}
                    />
                  </td>
                  <td>
                    <label htmlFor="LongQuestion">Long Question</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={LQSelected}
                      onChange={handleLQSelectedChange}
                      readOnly={!LQCheck}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={LQMarks}
                      onChange={handleLQMarksChange}
                      readOnly={!LQCheck}
                    />
                  </td>
                  <td>
                    <input type="number" value={totalLQMarks} readOnly />
                  </td>
                  <td>
                    {/* <input type="number"
                  
             /> */}
                  </td>
                  {/* <td>
      <input type="number"
      inputMode="num />
    </td> */}
                  <td>
                    <input
                      type="number"
                      value={availableLQCount}
                      id={availableLQCount}
                      name={availableLQCount}
                      readOnly
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="checkbox"
                      id="Grammar"
                      value="Grammar"
                      name="Grammar"
                      onChange={handleGChecked}
                    />
                  </td>
                  <td>
                    <label htmlFor="Grammar">Grammar</label>
                  </td>
                  <td>
                    <input
                      type="number"
                      value={GSelected}
                      onChange={handleGSelectedChange}
                      readOnly={!GCheck}
                    />
                  </td>
                  <td>
                    <input
                      type="number"
                      value={GMarks}
                      onChange={handleGMarksChange}
                      readOnly={!GCheck}
                    />
                  </td>
                  <td>
                    <input type="number" value={totalGMarks} readOnly />
                  </td>
                  <td>
                    {/* <input type="number"
                  
             /> */}
                  </td>
                  {/* <td>
      <input type="number"
      inputMode="num />
    </td> */}
                  <td>
                    <input
                      type="number"
                      value={availableGCount}
                      id={availableGCount}
                      name={availableGCount}
                      readOnly
                    />
                  </td>
                </tr>
              </table>
              <hr />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <button onClick={handleCalculateTotalMarks}>
                    Calculate Marks
                  </button>
                  <label htmlFor="totalMarks">Total Marks: </label>
                  <input
                    type="number"
                    name="totalMarks"
                    value={totalMarks}
                    readOnly
                  />
                </div>
                <div className="custom-file-upload">
                  <label htmlFor="logo-upload">Upload Logo</label>
                  <input
                    id="logo-upload"
                    type="file"
                    accept="image/*"
                    capture="camera"
                    onChange={handleFileUpload}
                  />
                </div>
                <button
                  onClick={printData}
                  disabled={totalMarks === "" || totalMarks === 0}
                >
                  Genrate Paper
                </button>
              </div>
              <hr />
            </div>
          </>
        )}
        {showPaper && (
          <>
            <div className="paper" id="printablediv">
              <div className="paperheader">
                <div>
                  <div className="PH1">Name:</div>
                  <div className="PH1">Roll #:</div>
                  <div className="PH1">Paper Code:</div>
                  <div className="PH1">Time:</div>
                </div>
                <div className="PH2">
                  {imageUrl && <img src={imageUrl} alt="Uploaded" />}
                  <h1>{schoolName}</h1>
                </div>
                <div>
                  <div className="PH1">Date: {date}</div>
                  <div className="PH1">Class: {className}</div>
                  <div className="PH1">Subject: {subjectName}</div>
                  <div className="PH1">T-Marks: {totalMarks}</div>
                </div>
              </div>
              <div className="paperBody">
                {MCQsCheck || TFCheck || FBCheck || CMCheck || GCheck ? (
                  <div className="PH3">Objective Types</div>
                ) : null}
                {MCQsCheck && (
                  <>
                    <div className="PH4">
                      <div className="QuestionHeading">
                        Question 1: Mark the correct answer from given options.
                      </div>
                      <div className="QuestionHeading">({totalMCQMarks})</div>
                    </div>
                    {MCQs &&
                      MCQs.map((element, index) => (
                        <>
                          <div className="PH6">
                            ({index + 1}) {element.Q}
                          </div>
                          <div className="PH5">
                            <p>(a)</p>
                            <p>{element.A}</p>
                            <p>(b)</p>
                            <p>{element.B}</p>
                            <p>(c)</p>
                            <p>{element.C}</p>
                            <p>(d)</p>
                            <p>{element.D}</p>
                          </div>
                        </>
                      ))}
                  </>
                )}
                {TFCheck && (
                  <>
                    <div className="PH4">
                      <div className="QuestionHeading">
                        Question {MCQsCheck ? 2 : 1}: Mark the statement either
                        true or false.
                      </div>
                      <div className="QuestionHeading">({totalTFMarks})</div>
                    </div>
                    {TF &&
                      TF.map((element, index) => (
                        <div className="PH8">
                          <span className="PH81">({index + 1}):</span>{" "}
                          <span className="PH82">{element.Q}</span>
                          <span className="PH83">({element.A})</span>
                          <span className="PH83">({element.B})</span>
                        </div>
                      ))}
                  </>
                )}

                {FBCheck && (
                  <>
                    <div className="PH4">
                      <div className="QuestionHeading">
                        Question{" "}
                        {MCQsCheck && TFCheck
                          ? 3
                          : MCQsCheck || TFCheck
                          ? 2
                          : 1}{" "}
                        : FIll in the blanks spaces.
                      </div>
                      <div className="QuestionHeading">({totalFBMarks})</div>
                    </div>
                    {FB &&
                      FB.map((element, index) => (
                        <>
                          <div className="PH6">
                            ({index + 1}) {element.Q}
                          </div>
                          <div className="PH5">
                            <p>(a)</p>
                            <p>{element.A}</p>
                            <p>(b)</p>
                            <p>{element.B}</p>
                            <p>(c)</p>
                            <p>{element.C}</p>
                            <p>(d)</p>
                            <p>{element.D}</p>
                          </div>
                        </>
                      ))}
                  </>
                )}

                {GCheck && (
                  <>
                    <div className="PH4">
                      <div className="QuestionHeading">
                        Question{" "}
                        {MCQsCheck && TFCheck && FBCheck
                          ? 4
                          : (MCQsCheck && TFCheck) ||
                            (MCQsCheck && FBCheck) ||
                            (TFCheck && FBCheck)
                          ? 3
                          : MCQsCheck || TFCheck || FBCheck
                          ? 2
                          : 1}
                        : Grammar Question.
                      </div>
                      <div className="QuestionHeading">({totalGMarks})</div>
                    </div>
                    {G &&
                      G.map((element, index) => (
                        <>
                          <div className="PH6">
                            ({index + 1}) {element.Q}
                          </div>
                          <div className="PH5">
                            <p>(a)</p>
                            <p>{element.A}</p>
                            <p>(b)</p>
                            <p>{element.B}</p>
                            <p>(c)</p>
                            <p>{element.C}</p>
                            <p>(d)</p>
                            <p>{element.D}</p>
                          </div>
                        </>
                      ))}
                  </>
                )}

                {CMCheck && (
                  <>
                    <div className="PH4">
                      <div className="QuestionHeading">
                        Question{" "}
                        {MCQsCheck && TFCheck && FBCheck && GCheck
                          ? 5
                          : (MCQsCheck && TFCheck && FBCheck) ||
                            (MCQsCheck && TFCheck && GCheck) ||
                            (MCQsCheck && GCheck && FBCheck) ||
                            (GCheck && TFCheck && FBCheck)
                          ? 4
                          : (MCQsCheck && TFCheck) ||
                            (MCQsCheck && FBCheck) ||
                            (MCQsCheck && GCheck) ||
                            (FBCheck && TFCheck) ||
                            (GCheck && TFCheck) ||
                            (FBCheck && GCheck)
                          ? 3
                          : MCQsCheck || TFCheck || FBCheck || GCheck
                          ? 2
                          : 1}
                        : Match Column 'A' with Column 'B'.
                      </div>
                      <div className="QuestionHeading">({totalCMMarks})</div>
                    </div>
                    <table>
                      <tr>
                        <th>Column A</th>
                        <th>Column B</th>
                        <th>Column C</th>
                      </tr>
                      {CM &&
                        CM.map((element) => {
                          
                          return (
                          <tr>
                            <td>{element.coulmnA}</td>
                            <td>{element.coulmnB}</td>
                            <td></td>
                          </tr>
                        )}
                        )}
                    </table>
                  </>
                )}
                {SQCheck && (
                  <>
                    <div className="PH3">Short Questions Section</div>
                    <div className="PH4">
                      <div className="QuestionHeading">
                        Question{" "}
                        {MCQsCheck && TFCheck && FBCheck && GCheck && CMCheck
                          ? 6
                          : (MCQsCheck && TFCheck && FBCheck && GCheck) ||
                            (MCQsCheck && TFCheck && FBCheck && CMCheck) ||
                            (MCQsCheck && TFCheck && GCheck && CMCheck) ||
                            (MCQsCheck && GCheck && FBCheck && CMCheck) ||
                            (GCheck && TFCheck && FBCheck && CMCheck)
                          ? 5
                          : (MCQsCheck && TFCheck && FBCheck) ||
                            (MCQsCheck && TFCheck && CMCheck) ||
                            (MCQsCheck && TFCheck && GCheck) ||
                            (MCQsCheck && GCheck && CMCheck) ||
                            (GCheck && CMCheck && FBCheck) ||
                            (MCQsCheck && CMCheck && FBCheck) ||
                            (MCQsCheck && GCheck && FBCheck) ||
                            (GCheck && TFCheck && FBCheck)
                          ? 4
                          : (MCQsCheck && TFCheck) ||
                            (MCQsCheck && FBCheck) ||
                            (MCQsCheck && GCheck) ||
                            (MCQsCheck && CMCheck) ||
                            (FBCheck && TFCheck) ||
                            (CMCheck && TFCheck) ||
                            (GCheck && TFCheck) ||
                            (FBCheck && GCheck) ||
                            (FBCheck && CMCheck) ||
                            (GCheck && CMCheck)
                          ? 3
                          : MCQsCheck || TFCheck || GCheck || FBCheck || CMCheck
                          ? 2
                          : 1}
                        : Write Short Answers of given Questions.
                      </div>
                      <div className="QuestionHeading">({totalSQMarks})</div>
                    </div>
                    {SQ &&
                      SQ.map((element, index) => (
                        <div className="PH7">
                          ({index + 1}): <span>{element}</span>
                          <hr />
                          <hr />
                        </div>
                      ))}
                  </>
                )}
                {LQCheck && (
                  <>
                    {LQ && (
                      <>
                        <div className="PH3">Subjective Type Section</div>
                        {LQ.map((element, index) => {
                          if (
                            MCQsCheck &&
                            TFCheck &&
                            FBCheck &&
                            GCheck &&
                            CMCheck &&
                            SQCheck
                          ) {
                            index = index + 6;
                          } else if (
                            (MCQsCheck &&
                              TFCheck &&
                              FBCheck &&
                              GCheck &&
                              CMCheck) ||
                            (MCQsCheck &&
                              TFCheck &&
                              FBCheck &&
                              GCheck &&
                              SQCheck) ||
                            (MCQsCheck &&
                              TFCheck &&
                              FBCheck &&
                              SQCheck &&
                              CMCheck) ||
                            (MCQsCheck &&
                              TFCheck &&
                              SQCheck &&
                              GCheck &&
                              CMCheck) ||
                            (MCQsCheck &&
                              SQCheck &&
                              FBCheck &&
                              GCheck &&
                              CMCheck) ||
                            (SQCheck && TFCheck && FBCheck && GCheck && CMCheck)
                          ) {
                            index = index + 5;
                          } else if (
                            (MCQsCheck && TFCheck && FBCheck && GCheck) ||
                            (MCQsCheck && TFCheck && FBCheck && CMCheck) ||
                            (MCQsCheck && TFCheck && FBCheck && SQCheck) ||
                            (GCheck && TFCheck && FBCheck && CMCheck) ||
                            (GCheck && TFCheck && FBCheck && SQCheck) ||
                            (MCQsCheck && TFCheck && GCheck && CMCheck) ||
                            (MCQsCheck && TFCheck && GCheck && SQCheck) ||
                            (MCQsCheck && TFCheck && CMCheck && SQCheck) ||
                            (MCQsCheck && GCheck && FBCheck && CMCheck) ||
                            (MCQsCheck && GCheck && SQCheck && CMCheck) ||
                            (CMCheck && TFCheck && FBCheck && SQCheck) ||
                            (GCheck && SQCheck && FBCheck && CMCheck) ||
                            (GCheck && SQCheck && TFCheck && CMCheck)
                          ) {
                            index = index + 4;
                          } else if (
                            (MCQsCheck && TFCheck && FBCheck) ||
                            (MCQsCheck && TFCheck && GCheck) ||
                            (MCQsCheck && TFCheck && CMCheck) ||
                            (MCQsCheck && TFCheck && SQCheck) ||
                            (MCQsCheck && FBCheck && GCheck) ||
                            (MCQsCheck && FBCheck && CMCheck) ||
                            (MCQsCheck && FBCheck && SQCheck) ||
                            (MCQsCheck && GCheck && CMCheck) ||
                            (MCQsCheck && GCheck && SQCheck) ||
                            (MCQsCheck && CMCheck && SQCheck) ||
                            (TFCheck && FBCheck && GCheck) ||
                            (TFCheck && FBCheck && CMCheck) ||
                            (TFCheck && FBCheck && SQCheck) ||
                            (TFCheck && GCheck && CMCheck) ||
                            (TFCheck && GCheck && SQCheck) ||
                            (TFCheck && CMCheck && SQCheck) ||
                            (FBCheck && GCheck && CMCheck) ||
                            (FBCheck && GCheck && SQCheck) ||
                            (FBCheck && CMCheck && SQCheck) ||
                            (GCheck && CMCheck && SQCheck)
                          ) {
                            index = index + 3;
                          } else if (
                            (MCQsCheck && TFCheck) ||
                            (MCQsCheck && FBCheck) ||
                            (MCQsCheck && GCheck) ||
                            (MCQsCheck && CMCheck) ||
                            (MCQsCheck && SQCheck) ||
                            (FBCheck && TFCheck) ||
                            (CMCheck && TFCheck) ||
                            (GCheck && TFCheck) ||
                            (SQCheck && TFCheck) ||
                            (FBCheck && GCheck) ||
                            (FBCheck && CMCheck) ||
                            (FBCheck && SQCheck) ||
                            (GCheck && SQCheck) ||
                            (CMCheck && SQCheck) ||
                            (GCheck && CMCheck)
                          ) {
                            index = index + 2;
                          } else if (
                            MCQsCheck ||
                            TFCheck ||
                            FBCheck ||
                            GCheck ||
                            CMCheck ||
                            SQCheck
                          ) {
                            index++;
                          }
                          return (
                            <div className="PH9">
                              <div className="PH4">
                                <div className="QuestionHeading">
                                  Question {index + 1}: {element}
                                </div>
                                <div className="QuestionHeading">
                                  ({LQMarks})
                                </div>
                              </div>
                              <hr /><hr /><hr /><hr /><hr />
                            </div>
                          );
                        })}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            <button onClick={printPaper}>Print Paper</button>
          </>
        )}
      </div>
    </div>
  );
};

export default AutoPaper;
