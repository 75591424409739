import React from "react";
import Sidebar from "./Sidebar";
import { useNavigate } from "react-router-dom";

const Main = () => {
  const navigate = useNavigate();
  function navigatation(e) {
    if (e === 'p')
      navigate('/profile')
      else if (e === 'a')
      navigate('/autoPaper')
      else if (e === 'm')
      navigate('/profile')
      else if (e === 'g')
      navigate('/profile')
  }
  
  return (
    <>
      <div className="mphm">
        <Sidebar />
        <div className="mpdbparenthm">
          <h1 className="mpdbheadinghm">
            Welcome to Abbasi Auto Paper Genrator
          </h1>
          <div className="mpdbboxesparenthm">
            <div className="mpdbboxeshm" onClick={() => {navigatation('p')}}>
              <h1>Profile</h1>
              <p>Your Profile including your user ID provided by Abbasi Auto Paper Genrator. You can change your paasword if you feel any security issue for yur account. your satisfication is our praiorty.</p>
            </div>
            <div className="mpdbboxeshm" onClick={() => {navigatation('a')}}>
              <h1>Auto Paper</h1>
              <p>You Can ceate paper automatically just on the selection of class, subject, chapters, type of query and number of questions.</p>
            </div>
            <div className="mpdbboxeshm" onClick={() => {navigatation('m')}}>
              <h1>Mannual Paper</h1>
              <p>You Can ceate paper manually. After the selection of class, subject, chapters, type of query and nimber of questions you will se the list of questions select that and can add yourself also.</p>
            </div>
            <div className="mpdbboxeshm" onClick={() => {navigatation('g')}}>
              <h1>Guide</h1>
              <p>If you don't know how to use this web app dont worry we have guide book for you feel free</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
