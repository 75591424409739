import React from "react";
import Button from "./Button";
import { auth } from "./firebase";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router";

function Sidebar() {
  const navigate = useNavigate();
  function handleLogout() {
    signOut(auth)
      .then(() => {
        console.log("signed out");
        navigate("/");
      })
      .catch((error) => {
        alert(error);
      });
  }

  return (
    <>
    <div className="sb">
      <div className="sbcdhm">
        <Button Link='/home' name="Dashboard" />
        <Button Link='/profile' name="Profile" />
        <Button Link='/autoPaper' name="Auto Paper" />
        <Button Link='/mannulPaper' name="Mannual Paper" />
        <Button Link='/guide' name="Guide" />
      </div>
      <div className="sblbhm">
        <Button onClick={handleLogout} name="Logout" />
      </div>
    </div>
    </>
  );
}

export default Sidebar;
